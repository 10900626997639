import common from "@/common";

let form_options = {}
form_options.company = []

export function reloadFieldData() {
  // init Arrays
  ;[
    form_options.company,
  ].forEach(arr => arr.length = 0)

  // Todo: no need to call this whilst account is not admin
  common.getCompanyOptions()
    .then(res => {
      form_options.company.push.apply(form_options.company, res)
    })
}

export default [
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    rule: 'required',
    options: form_options.company,
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityID',
    label: 'common.facility',
    type: 'slot',
    rule: 'required',
    slot: 'facility',
    show: common.isAdmin()
  },
  {
    name: 'typeName',
    label: 'bed_type.typeName',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'isActive',
    label: 'bed_type.isActive',
    type: 'select',
    edit_show: true,
    rule: 'required',
    options: [
      {text: common.getI18n('common.enable'), value: true},
      {text: common.getI18n('common.disable'), value: false},
    ]
  },



]
